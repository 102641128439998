<template>
  <b-modal
    :id="id"
    hide-footer
    hide-header
    :centered="true"
    @hidden="!isCancel || $emit('close')"
  >
    <div class="py-[32px] px-[10px] flex flex-col items-center justify-center gap-[16px]">
      <slot />
      <img
        :src="`https://storage.googleapis.com/komerce/core/icon-popup-${type}.png`"
        :alt="`${type}-popup`"
        width="100"
        height="100"
      >
      <div class="text-[#333333] [&>*]:text-center flex flex-col items-center justify-center gap-[8px]">
        <b class="text-[24px]">{{ title }}</b>
        <p v-if="text !== ''">
          {{ text }}
        </p>
      </div>
      <div class="flex gap-[12px] flex-wrap [&>*]:grow w-full">
        <b-button
          :variant="`${confirmText ? 'outline-' : ''}primary`"
          @click="onClose"
        >
          {{ cancelText }}
        </b-button>
        <b-button
          v-if="confirmText"
          variant="primary"
          @click="onConfirm"
        >
          {{ confirmText }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'global-default-modal',
    },
    type: {
      type: String,
      default: 'warning',
    },
    title: {
      type: String,
      default: 'Yakin reset berita?',
    },
    text: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String || null,
      default: null,
    },
    cancelText: {
      type: String,
      default: 'Batalkan',
    },
  },
  data() {
    return {
      isCancel: true,
    }
  },
  methods: {
    onConfirm() {
      this.isCancel = false
      this.$emit('confirm')
      this.$bvModal.hide(this.id)
      setTimeout(() => { this.isCancel = true }, 1000)
    },
    onClose() {
      this.$emit('close')
      this.$bvModal.hide(this.id)
    },
  },
}
</script>
